<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>

    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="card-padding">
        <v-form ref="frm_po_search">
          <v-row>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>Auction
                center</label
              >

              <v-autocomplete
                :items="auctionCenters"
                item-text="auction_center_name"
                item-value="id"
                hide-details="auto"
                v-model="auctionCenter"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-3
                  mb-0
                "
                outlined
                height="46"
                single-line
                :rules="[(v) => !!v || 'Auction center is required']"
                placeholder="Select auction center"
              >
                <template v-slot:selection="{ item }">
                  <v-text label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-caption ls-0">{{
                      item.auction_center_name
                    }}</span>
                  </v-text>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>Season</label
              >

              <v-autocomplete
                :items="seasons"
                item-value="id"
                hide-details="auto"
                v-model="season"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-3
                  mb-0
                "
                outlined
                height="46"
                single-line
                :rules="[(v) => !!v || 'Season is required']"
                placeholder="Select Season"
              >
                <template v-slot:selection="{ item }">
                  <v-text
                    name="ss_name"
                    label
                    color="bg-default"
                    class="py-1 px-2 my-0"
                  >
                    <span class="text-caption ls-0">{{ item.name }}</span>
                  </v-text>
                </template>
                <template v-slot:item="{ item }">
                  <v-text
                    name="ss_name"
                    label
                    color="bg-default"
                    class="py-1 px-2 my-0"
                  >
                    <span class="text-caption ls-0">{{ item.name }}</span>
                  </v-text>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col class="d-flex justify-end mx-5">
            <v-btn
              @click="clearfilter"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-light
                px-6
                mt-2
                mr-2
              "
              >Clear</v-btn
            >

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="text-capitalize btn-ls btn-primary bg-success px-6 mt-2"
              @click="search"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- ## Dispatch Master -->
    <v-card class="card-shadow border-radius-xl mt-5" v-show="showMaster">
      <v-card-text class="card-padding">
        <v-data-table
          :headers="dispatchMasterHeader"
          :items="dispatchMaster"
          class="table"
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.recieved_date`]="{ item }">
            {{ formatDate(item.recieved_date) }}</template
          >

          <template v-slot:[`item.action1`]="{ item }">
            <v-btn
              @click="viewDetails(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class=""
              color="green"
              >View
            </v-btn></template
          >

          <template v-slot:[`item.action2`]="{ item }">
            <v-btn
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class=""
              color="green"
              @click="excelImport(item.child)"
              >Export as excel
            </v-btn></template
          >
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- ## Dispatch child -->
    <v-dialog v-model="dialog" persistent max-width="1100">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-data-table
            :headers="dispatchChildHeader"
            :items="dispatchchild"
            class="table"
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template v-slot:top class="mt-5">
              <v-toolbar flat>
                <v-toolbar-title>Dispatch List (Recieved)</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                  @click="excelImport(dispatchchild)"
                  icon
                  elevation="0"
                  :ripple="false"
                  color="green"
                  >Export
                </v-btn>

                <v-btn
                  @click="closeDialogue"
                  icon
                  elevation="0"
                  :ripple="false"
                  class="ml-5"
                  color="red"
                  >close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
import apiDispatch from "../../dispatch/api";
import apiMaster from "../../../master/master-get-api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      purchaseType: "AUCTION",
      auctionCenters: [],
      auctionCenter: null,
      seasons: [],
      season: null,
      seasonSales: [],
      seasonSale: null,
      auction_vendor: [],
      auction_vendors: [],
      auction_poNumber: [],
      auction_poNumbers: [],
      overlay: false,
      dialog: false,
      //dispatch Code
      dispatchCodes: [],
      dispatchCode: null,

      // Data  Table
      dispatchMaster: [],
      showMaster: false,
      dispatchchild: [],
      dispatchChildHeader: [
        {
          text: "Batch No:",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Lot No:",
        //   value: "lot_no",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "No: of Package(Ordered) ",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "No: of Package(Recieved)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantity (Ordered)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantity (Recieved)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
      ],
      dispatchMasterHeader: [
        {
          text: "Code",
          value: "dispatch_code",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Deleivered On",
          value: "recieved_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },

        {
          text: "Vehicle No:",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "action1",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "action2",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate.formatDate(date);
    },

    async initialize() {
      this.auctionCenters = await apiMaster.getAuctionCenter();

      this.seasons = await apiMaster.getSeason();
    },
    async filterSeasonSale() {
      this.overlay = true;
      this.seasonSale = null;
      this.seasonSales = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.season,
        this.auctionCenter
      );
      this.overlay = false;
    },
    async getAuctionVendors() {
      this.overlay = true;
      this.auction_vendors = await apiDispatch.getAuctionVenders(
        this.seasonSale
      );
      this.overlay = false;
    },

    poSearchValidate() {
      return this.$refs.frm_po_search.validate();
    },
    async getDispatchCode() {
      this.overlay = true;
      this.dispatchCodes = await api.getRecievedDispatchCode(this.seasonSale);
      this.overlay = false;
    },
    async searchPoByVendors() {
      // if (this.poSearchValidate()) {
      this.overlay = true;
      let params = {
        vendors: this.auction_vendor,
        season_sale: this.seasonSale,
      };

      this.auction_poNumbers = await apiDispatch.getPoByVendorsAndSeasonSale(
        params
      );
      this.overlay = false;
      // }
    },

    searchValidate() {
      // return this.$refs.frm_search.validate();
    },
    async search() {
      try {
        // console.log("PO ", this.auction_poNumber);
        this.overlay = true;
        this.dispatchMaster =
          await api.getRecievedDispatchMasterBySeasonAndAuctionCenter(
            this.auctionCenter,
            this.season
          );
        this.showMaster = true;
        this.overlay = false;
      } catch (error) {
        console.log(error);
      }
    },
    clearfilter() {
      this.auctionCenter = null;
      this.season = null;
      this.seasonSale = null;
      this.auction_vendor = null;
      this.auction_poNumber = null;
      // this.$refs.frm_search.resetValidation();
      this.$refs.frm_po_search.resetValidation();
    },
    async excelImport(item) {
      let param = [];
      item.forEach((el) => {
        let data = {
          item_name: el.item_name,
          batch_no: el.batch_no,
        };
        param.push(data);
      });
      await api.downloadExcel(param);
    },

    async viewDetails(item) {
      this.overlay = true;
      this.dialog = true;

      this.dispatchchild = await api.getChildByMaster(item.id);
      console.log("this.dispatchchild", this.dispatchchild);
      this.overlay = false;
    },
    closeDialogue() {
      this.dialog = false;
      this.dispatchchild = [];
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style></style>
