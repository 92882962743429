<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-row class="mt-2">
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                >Purchase Type</label
              ><v-form ref="frmType">
                <v-select
                  @change="getUi"
                  v-model="purchaseType"
                  :items="purchaseTypes"
                  item-text="type"
                  item-value="type"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Purchase Type"
                  :rules="[(v) => !!v || 'Purchase Type is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-select>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <AuctionDeliveryNotes
        v-if="showAuction"
        class="mt-5"
      ></AuctionDeliveryNotes>
      <PrivateDeliveryNotes
        v-if="showPrivate"
        class="mt-5"
      ></PrivateDeliveryNotes>
    </v-container>
  </div>
</template>

<script>
import PrivateDeliveryNotes from "./private.vue";
import AuctionDeliveryNotes from "./auction.vue";
export default {
  components: {
    PrivateDeliveryNotes,
    AuctionDeliveryNotes,
  },
  data() {
    return {
      overlay: false,
      showAuction: false,
      showPrivate: false,
      purchaseTypes: [
        {
          type: "AUCTION",
        },
        { type: "PRIVATE" },
      ],
      purchaseType: "",
    };
  },
  methods: {
    getUi() {
      if (this.purchaseType == "PRIVATE") {
        this.showAuction = false;
        this.showPrivate = true;
      }

      if (this.purchaseType == "AUCTION") {
        this.showAuction = true;
        this.showPrivate = false;
      }
    },
  },
};
</script>
